/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://kxkn66w54beshomnbreau7iw5m.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:8f2ecd7d-046b-4529-b029-4c2539bba525",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_0D3PoOYE8",
    "aws_user_pools_web_client_id": "6b96mlbrk70ajfh969sekvbkog",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "smartport1fd30e95c68846669d7fec4ba60d518e114320-bddemo",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
